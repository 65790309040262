import GridController from './grid_controller.js'

export default class extends GridController {
    initialize() {
        this.gridName = 'ProductionFacilitiesYearlyReportsGrid';
        this.destroyActionValue = false;
        this.gridConfig.columns = [
            {
                title: "ID productiezetel",
                field: "id",
                type: "number",
                width: "100px",
                hidden: true,
            },
            {
                title: "Naam productiezetel",
                field: "name",
                type: "string",
                widht: "300px",
                filterable: {
                    cell: {
                        delay: 1500
                    }
                }
            },
        ];
        this.gridConfig.dataSource.schema = {
            data: "production_facilities",
            model: {
                id: "id",
                fields: {
                    id: { type: "number" },
                    name: { type: "string" },
                    uri: { type: "string" },
                    can_show: { type: "boolean" },
                    can_edit: { type: "boolean" }
                }
            },
            total: "total"
        };
        this.gridConfig.pageable = true;
        this.gridConfig.dataSource.pageSize = 50;
        this.gridConfig.dataSource.serverPaging = true;
        this.gridConfig.dataSource.serverSorting = true;
        this.gridConfig.dataSource.serverFiltering = true;
        super.initialize();
    }
}
