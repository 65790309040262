import GridController from "./grid_controller.js"

export default class extends GridController {
    static values = {
        excelurl: String,
    }

    yearFilterTemplate = function (args) {
        args.element.kendoNumericTextBox({
            decimals: 0,
            format: "####",
            max: 9999,
            min: 1900,
            restrictDecimals: true,
        });
    }

    statusFilterTemplate = function(args) {
        args.element.kendoDropDownList({
            dataSource: [{value: 1, text: "Actief"}, {value: 2, text: "Niet actief"}],
            optionLabel: "--Selecteer--",
            dataTextField: "text",
            dataValueField: "value",
            valuePrimitive: true
        });
    }

    initialize() {
        this.gridName = 'YearlyReportsGrid';
        this.destroyActionValue = false;
        // this.gridConfig.dataSource.group = [
        //     {
        //         field: "year",
        //         aggregates: [
        //             { field: "category", aggregate: "count" },
        //             { field: "quarter_1", aggregate: "sum" },
        //             { field: "quarter_2", aggregate: "sum" },
        //             { field: "quarter_3", aggregate: "sum" },
        //             { field: "quarter_4", aggregate: "sum" },
        //             { field: "year_total", aggregate: "sum" },
        //         ]
        //     },
        //     {
        //         field: "category",
        //         aggregates: [
        //             { field: "category", aggregate: "count" },
        //             { field: "quarter_1", aggregate: "sum" },
        //             { field: "quarter_2", aggregate: "sum" },
        //             { field: "quarter_3", aggregate: "sum" },
        //             { field: "quarter_4", aggregate: "sum" },
        //             { field: "year_total", aggregate: "sum" },
        //         ]
        //     },
        //     {
        //         field: "product",
        //         aggregates: [
        //             { field: "category", aggregate: "count" },
        //             { field: "quarter_1", aggregate: "sum" },
        //             { field: "quarter_2", aggregate: "sum" },
        //             { field: "quarter_3", aggregate: "sum" },
        //             { field: "quarter_4", aggregate: "sum" },
        //             { field: "year_total", aggregate: "sum" },
        //         ]
        //     }
        // ];
        // this.gridConfig.dataSource.aggregate = [
        //     { field: "category", aggregate: "count" },
        //     { field: "quarter_1", aggregate: "sum" },
        //     { field: "quarter_2", aggregate: "sum" },
        //     { field: "quarter_3", aggregate: "sum" },
        //     { field: "quarter_4", aggregate: "sum" },
        //     { field: "year_total", aggregate: "sum" },
        // ];

        this.gridConfig.columns = [
            {
                title: this.localValue == 'nl' ? "CERTIPRO-Code" : "CERTIPRO-Code",
                field: "certipro_code",
                type: "string",
                width: "100px",
                filterable: {
                    cell: {
                        delay: 1500
                    }
                }
            },
            {
                title: this.localeValue == 'nl' ? "Productielocatie" : "Lieu de production",
                field: "production_facility",
                type: "string",
                width: "300px",
                filterable: {
                    cell: {
                        delay: 1500
                    }
                }
            },
            {
                title: this.localeValue == 'nl' ? "Adres" : "Adresse",
                field: "address",
                type: "string",
                width: "300px",
                filterable: {
                    cell: {
                        delay: 1500
                    }
                }
            },
            {
                title: this.localeValue == 'nl' ? "Jaar" : "Année",
                field: "year",
                filterable: {
                    cell: {
                        template: null // must be assigned after restoreGridState
                    }
                },
                type: "number",
                width: "100px",
            },
            // {
            //     title: "Acceptatie",
            //     field: "acceptation",
            //     type: "boolean",
            //     width: "100px",
            //     hidden: true,
            // },
            // {
            //     title: "Category",
            //     field: "category",
            //     type: "string",
            //     width: "300px",
            //     hidden: true,
            //     aggregates: ["count"],
            //     groupHeaderTemplate: "Category #=value# (aantal: #=count#)",
            // },
            // {
            //     title: "Product",
            //     field: "product",
            //     type: "string",
            //     width: "200px",
            //     hidden: true,
            // },
            // {
            //     title: "Kwartaal 1",
            //     field: "quarter_1",
            //     type: "number",
            //     width: "100px",
            //     hidden: true,
            //     aggregates: ["sum"],
            //     footerTemplate: "#=sum# ton",
            //     groupFooterTemplate: "#=sum# ton",
            // },
            // {
            //     title: "Kwartaal 2",
            //     field: "quarter_2",
            //     type: "number",
            //     width: "100px",
            //     hidden: true,
            //     aggregates: ["sum"],
            //     footerTemplate: "#=sum# ton",
            //     groupFooterTemplate: "#=sum# ton",
            // },
            // {
            //     title: "Kwartaal 3",
            //     field: "quarter_3",
            //     type: "number",
            //     width: "100px",
            //     hidden: true,
            //     aggregates: ["sum"],
            //     footerTemplate: "#=sum# ton",
            //     groupFooterTemplate: "#=sum# ton",
            // },
            // {
            //     title: "Kwartaal 4",
            //     field: "quarter_4",
            //     type: "number",
            //     width: "100px",
            //     hidden: true,
            //     aggregates: ["sum"],
            //     footerTemplate: "#=sum# ton",
            //     groupFooterTemplate: "#=sum# ton",
            // },
            // {
            //     title: "Jaartotaal",
            //     field: "year_total",
            //     type: "number",
            //     width: "100px",
            //     aggregates: ["sum"],
            //     footerTemplate: "#=sum# ton",
            //     groupFooterTemplate: "#=sum# ton",
            // },
            // {
            //     title: "Aangemaakt op",
            //     field: "created_at",
            //     type: "date",
            //     width: "200px",
            //     format: "{0:yyyy/MM/dd HH:mm:ss}",
            //     hidden: true,
            // },
            // {
            //     title: "Laatst geupdate op",
            //     field: "updated_at",
            //     type: "date",
            //     width: "200px",
            //     format: "{0:yyyy/MM/dd HH:mm:ss}",
            //     hidden: true,
            // },
            {
                title: this.localeValue == 'nl' ? "Q1 ingediend" : "Q1 soumis",
                field: "quarter_1_submitted",
                type: "boolean",
                hidden: true
            },
            {
                title: this.localeValue == 'nl' ? "Q2 ingediend" : "Q2 soumis",
                field: "quarter_2_submitted",
                type: "boolean",
                hidden: true
            },
            {
                title: this.localeValue == 'nl' ? "Q3 ingediend" : "Q3 soumis",
                field: "quarter_3_submitted",
                type: "boolean",
                hidden: true
            },
            {
                title: this.localeValue == 'nl' ? "Q4 ingediend" : "Q4 soumis",
                field: "quarter_4_submitted",
                type: "boolean",
                hidden: true
            },
            {
                title: this.localeValue == 'nl' ? "Status" : "statut",
                field: "status",
                type: "number",
                template: "<span class='badgeTemplate'>#=status#</span>",
                width: 120,
                filterable: {
                    cell: {
                        template: null // must be assigned after restoreGridState
                    },
                    operators: {
                        number: {
                            eq: "Is equal to",
                            neq: "Is not equal to"
                        }
                    }
                }
            },
        ];
        this.gridConfig.dataSource.schema = {
            data: "yearly_reports",
            model: {
                id: "certipro_code",
                fields: {
                    certipro_code: { type: "string"},
                    production_facility: { type: "string"},
                    address: { type: "string" },
                    status: { type: "number" },
                    year: { type: "number" },
                    quarter_1_submitted: { type: "boolean" },
                    quarter_2_submitted: { type: "boolean" },
                    quarter_3_submitted: { type: "boolean" },
                    quarter_4_submitted: { type: "boolean" },
                    uri: { type: "string" },
                    can_show: { type: "boolean" },
                    can_edit: { type: "boolean" },
                    can_destroy: { type: "boolean" }
                }
            },
            total: "total"
        };
        this.gridConfig.pageable = true;
        this.gridConfig.dataSource.pageSize = 50;
        this.gridConfig.dataSource.serverPaging = true;
        this.gridConfig.dataSource.serverSorting = true;
        this.gridConfig.dataSource.serverFiltering = true;
        this.gridConfig.groupable = true;
        this.gridConfig.toolbar = [];

        this.gridConfig.toolbar.push({ name: "BrekersExcel", text: this.localeValue == 'nl' ? "Exporteer brekers naar Excel" : "Exporter des broyeurs vers Excel", iconClass: "k-icon k-i-file-excel"});
        this.gridConfig.toolbar.push({ name: "SorteerdersExcel", text: this.localeValue == 'nl' ? "Exporteer sorteerders naar Excel" : "Exporter des sorteurs vers Excel", iconClass: "k-icon k-i-file-excel"});

        super.initialize();

        $('.k-grid-BrekersExcel').on('click', function(event) {
            event.preventDefault();
            this.generateExcel(1);
        }.bind(this));

        $('.k-grid-SorteerdersExcel').on('click', function(event) {
            event.preventDefault();
            this.generateExcel(3);
        }.bind(this));
    }

    // onDataBound(event) {
    //     const grid = this;
    //     grid.table.find(".k-grouping-row").each(function() {
    //         grid.collapseGroup(this);
    //     });
    // }

    onDataBound(event) {
        const grid = this;
        grid.table.find("tr").each(function () {
            const dataItem = grid.dataItem(this);
            // const themeColor = dataItem.status === 'Actief' ? 'success' : 'error';
            // const text = dataItem.status;
            const themeColor = dataItem.status === 1 ? 'success' : 'error';
            const text = dataItem.status === 1 ? 'Actief' : 'Inactief';
            
            $(this).find(".badgeTemplate").kendoBadge({
                themeColor: themeColor,
                text: text,
            });

            kendo.bind($(this), dataItem);
        });
    }

    generateExcel(application_type) {
        $.ajax({
            url: this.excelurlValue,
            data: {application_type: application_type},
            xhrFields: {
                responseType: 'blob'
            },
            type: 'GET',
            beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
            success: function(data, textStatus, jqXHR) {
                const filenameHeader = jqXHR.getResponseHeader("content-disposition").split(';')[1];
                const filename = filenameHeader.substring(filenameHeader.indexOf("=") + 1).replaceAll("\"", "");
                const url = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.append(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                alert('');
            },
        });
    }
}
