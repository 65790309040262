// Entry point for the build script in your package.json
import "./turbo"
import "./jquery"
import "./jszip"
import * as bootstrap from "bootstrap"
import "@progress/kendo-ui/js/kendo.badge.min"
import "@progress/kendo-ui/js/kendo.datetimepicker.min"
import "@progress/kendo-ui/js/kendo.dropdownlist.min"
import "@progress/kendo-ui/js/kendo.grid.min"
import "@progress/kendo-ui/js/kendo.tooltip.min"
import "@progress/kendo-ui/js/messages/kendo.messages.fr-BE.min"
import "@progress/kendo-ui/js/messages/kendo.messages.nl-BE.min"
import "./controllers"
