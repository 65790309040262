import {Controller} from "@hotwired/stimulus"

// This controller is used to show/hide elements based on the state of a checkbox.
export default class extends Controller {
    static targets = ["checkbox", "toggleable"];
    static values = {
        showWhenChecked: Boolean // Determines whether the toggleable elements should be shown when the checkbox is checked or unchecked.
    }

    // This method is called when the controller is connected to the DOM.
    // It sets up the event listener for the checkbox and calls the toggle method one time to show/hide the toggleable elements.
    connect() {
        const controller = this;
        this.toggle(controller.showWhenCheckedValue ? this.checkboxTarget.checked : !this.checkboxTarget.checked);
        $(this.checkboxTarget).on('change', function (event) {
            controller.toggle(controller.showWhenCheckedValue ? event.target.checked : !event.target.checked);
        });
    }

    // Toggles all toggleable elements.
    toggle(show) {
        const hiddenClass = "d-none";
        for (let toggleable of this.toggleableTargets) {
            show ? toggleable.classList.remove(hiddenClass) : toggleable.classList.add(hiddenClass);
        }
    }
}