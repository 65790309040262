import GridController from "./grid_controller.js"

export default class extends GridController {
    initialize() {
        this.gridName = 'HydromixTechnicalSheetsGrid';
        this.gridConfig.columns = [
            {
                title: this.localeValue == 'fr' ? "Entreprise" : "Bedrijf",
                field: "vergunninghouder",
                type: "string",
            },
            {
                title: this.localeValue == 'fr' ? "Code fiche technique" : "Code technische fiche",
                field: "nummertf",
                type: "string",
            },
            {
                title: this.localeValue == 'fr' ? "Norme" : "Norm",
                field: "norm",
                type: "string",
                media: "(min-width: 1080px)",
                width: 100
            },
            {
                title: this.localeValue == 'fr' ? "Ville de production" : "Gemeente productiezetel",
                field: "gemeenteprod",
                type: "string",
                media: "(min-width: 1080px)"
            },
            {
                title: this.localeValue == 'fr' ? "Code postal de production" : "Postcode productiezetel",
                field: "postprod",
                type: "string",
                media: "(min-width: 1080px)",
                width: 100
            },
            {
                title: this.localeValue == 'fr' ? "Statut" : "Status",
                field: "status",
                type: "string",
                media: "(min-width: 1440px)"
            },
        ];
        this.addDownloadAction();
        super.initialize();
        this.grid.dataSource.sort({ field: "bedrijf", dir: "asc" });
    }

    downloadTechnicalSheet(event) {
        event.preventDefault();
        const rowData = this.dataItem($(event.target).closest("tr"));
        window.location.href = rowData.uri;
    }

    addDownloadAction() {
        this.addActionsColumn();
        this.gridConfig.columns[this.actionColumnIndex].width += this.actionButtonWidth;
        this.gridConfig.columns[this.actionColumnIndex].command.push({
            name: "download-button",
            text: " ",
            iconClass: "k-icon k-i-file-pdf",
            click: this.downloadTechnicalSheet,
        });
    }
}
