import GridController from "./grid_controller.js"


export default class extends GridController {
    initialize() {
        this.gridName = 'ProductionFacilityUsersGrid';
        this.gridConfig.columns = [
            {
                title: "ID",
                field: "id",
                type: "number",
                width: "100px",
                hidden: true,
            },
            {
                title: "Functie",
                field: "function",
                type: "string",
                width: "200px",
            },
            {
                title: "Voornaam",
                field: "firstname",
                type: "string",
                width: "200px",
            },
            {
                title: "Achternaam",
                field: "lastname",
                type: "string",
                width: "200px",
            },
            {
                title: "Email",
                field: "email",
                type: "string",
                width: "300px",
            },
            {
                title: "Administrator",
                field: "admin_role",
                type: "boolean",
                width: "100px",
                hidden: true,
            },
            {
                title: "Taal",
                field: "preferred_locale",
                type: "string",
                width: "100px",
                hidden: true
            },
            {
                title: "Aangemaakt op",
                field: "created_at",
                type: "date",
                width: "200px",
                format: "{0:yyyy/MM/dd HH:mm:ss}",
                hidden: true,
            },
            {
                title: "Laatst geupdate op",
                field: "updated_at",
                type: "date",
                width: "200px",
                format: "{0:yyyy/MM/dd HH:mm:ss}",
                hidden: true,
            },
        ];
        super.initialize();
    }
}