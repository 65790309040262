import GridController from "./grid_controller.js"


export default class extends GridController {
    preferred_localeFilterTemplate = function(args) {
        args.element.kendoDropDownList({
            dataSource: [{value: "nl", text: "Nederlands"},{value: "fr", text: "Français"}],
            optionLabel: "--Select--",
            dataTextField: "text",
            dataValueField: "value",
            valuePrimitive: true
        });
    }

    initialize() {
        this.gridName = 'UsersGrid';
        this.gridConfig.columns = [
            {
                title: "ID",
                field: "id",
                type: "number",
                hidden: true,
            },
            {
                title: "Voornaam",
                field: "firstname",
                type: "string",
                width: "200px",
                filterable: {
                    cell: {
                        delay: 1500
                    }
                }
            },
            {
                title: "Achternaam",
                field: "lastname",
                type: "string",
                filterable: {
                    cell: {
                        delay: 1500
                    }
                }
            },
            {
                title: "Email",
                field: "email",
                type: "string",
                filterable: {
                    cell: {
                        delay: 1500
                    }
                }
            },
            {
                title: "Taal",
                field: "preferred_locale",
                type: "string",
                width: 100,
                filterable: {
                    cell: {
                        template: null // must be assigned after restoreGridState
                    },
                    operators: {
                        string: {
                            eq: "Is equal to",
                            neq: "Is not equal to"
                        }
                    },
                }
            },
            {
                title: "Actief",
                field: "active",
                type: "boolean",
                template: "<span class='badgeTemplate'>#=active#</span>",
                width: 220,
            },
            {
                title: "Administrator",
                field: "admin_role",
                type: "boolean",
                hidden: true,
            },
            {
                title: "Aangemaakt op",
                field: "created_at",
                type: "date",
                width: "200px",
                format: "{0:yyyy/MM/dd HH:mm:ss}",
                hidden: true,
            },
            {
                title: "Laatst geupdate op",
                field: "updated_at",
                type: "date",
                format: "{0:yyyy/MM/dd HH:mm:ss}",
                hidden: true,
            },
        ];
        this.gridConfig.dataSource.schema = {
            data: "users",
            model: {
                id: "id",
                fields: {
                    id: { type: "number" },
                    firstname: { type: "string" },
                    lastname: { type: "string" },
                    email: { type: "string" },
                    admin_role: { type: "boolean" },
                    active: { type: "boolean" },
                    preferred_locale: { type: "string" },
                    created_at: { type: "date" },
                    updated_at: { type: "date" },
                    uri: { type: "string" },
                    can_show: { type: "boolean" },
                    can_edit: { type: "boolean" },
                    can_destroy: { type: "boolean" }
                }
            },
            total: "total"
        };
        this.gridConfig.dataSource.transport.parameterMap = function(data, type) {
            if (data.filter) {
                return {
                    filter: {
                        logic: data.filter.logic,
                        filters: data.filter.filters.map((filter) => {
                            return {
                                'field': filter.field,
                                'operator': filter.operator,
                                'value': (filter.field === 'created_at' || filter.field === 'updated_at' ? kendo.toString(filter.value, "yyyy-MM-ddTHH:mm:sszzz") : filter.value)
                            };
                        })
                    },
                    skip: data.skip,
                    sort: data.sort,
                    take: data.take
                };
            } else {
                return {
                    skip: data.skip,
                    sort: data.sort,
                    take: data.take
                };
            }
        };
        this.gridConfig.pageable = true;
        this.gridConfig.dataSource.pageSize = 50;
        this.gridConfig.dataSource.serverPaging = true;
        this.gridConfig.dataSource.serverSorting = true;
        this.gridConfig.dataSource.serverFiltering = true;
        super.initialize();
    }

    onDataBound(event) {
        const grid = this;
        grid.table.find("tr").each(function () {
            const dataItem = grid.dataItem(this);
            const themeColor = dataItem.active ? 'success' : 'error';
            const text = dataItem.active ? 'Actief' : 'Niet actief'

            $(this).find(".badgeTemplate").kendoBadge({
                themeColor: themeColor,
                text: text,
            });

            kendo.bind($(this), dataItem);
        });
    }
}
