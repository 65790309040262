import GridController from "./grid_controller.js"


export default class extends GridController {
    initialize() {
        this.gridName = 'UserProductionFacilitiesGrid';
        this.gridConfig.columns = [
            {
                title: "ID",
                field: "id",
                type: "number",
                width: "100px",
                hidden: true,
            },
            {
                title: "CERTIPRO-code",
                field: "certipro_code",
                type: "string",
                width: "50px",
            },
            {
                title: "Naam",
                field: "name",
                type: "string",
                width: "180px",
            },
            {
                title: "Adres",
                field: "address",
                type: "string",
                width: "180px",
            },
            {
                title: "Hoofdzetel",
                field: "company",
                type: "string",
                width: "180px",
            },
            {
                title: "Functie",
                field: "function",
                type: "string",
                width: "160px",
            },
        ];
        super.initialize();
    }
}