import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["quarter_1_total", "quarter_2_total", "quarter_3_total", "quarter_4_total", "year_total", "quarter_1", "quarter_2", "quarter_3", "quarter_4", "year_prod_total"];

    initialize() {
        this.quarter_1_totalTarget.textContent = String(this.quarter_1Targets.reduce((previous, current) => previous += (isNaN(current.value.replace(',', '.')) ? 0 : Number(current.value.replace(',', '.'))), 0)).replace('.', ',');
        this.quarter_2_totalTarget.textContent = String(this.quarter_2Targets.reduce((previous, current) => previous += (isNaN(current.value.replace(',', '.')) ? 0 : Number(current.value.replace(',', '.'))), 0)).replace('.', ',');
        this.quarter_3_totalTarget.textContent = String(this.quarter_3Targets.reduce((previous, current) => previous += (isNaN(current.value.replace(',', '.')) ? 0 : Number(current.value.replace(',', '.'))), 0)).replace('.', ',');
        this.quarter_4_totalTarget.textContent = String(this.quarter_4Targets.reduce((previous, current) => previous += (isNaN(current.value.replace(',', '.')) ? 0 : Number(current.value.replace(',', '.'))), 0)).replace('.', ',');
        this.year_totalTarget.textContent = String(Number(this.quarter_1_totalTarget.textContent.replace(',', '.')) + Number(this.quarter_2_totalTarget.textContent.replace(',', '.')) + Number(this.quarter_3_totalTarget.textContent.replace(',', '.')) + Number(this.quarter_4_totalTarget.textContent.replace(',', '.'))).replace('.', ',');
        for (const [i, year_prod_total] of this.year_prod_totalTargets.entries()) {
            year_prod_total.value = String((isNaN(this.quarter_1Targets[i].value.replace(',', '.')) ? 0 : Number(this.quarter_1Targets[i].value.replace(',', '.'))) + (isNaN(this.quarter_2Targets[i].value.replace(',', '.')) ? 0 : Number(this.quarter_2Targets[i].value.replace(',', '.'))) + (isNaN(this.quarter_3Targets[i].value.replace(',', '.')) ? 0 : Number(this.quarter_3Targets[i].value.replace(',', '.'))) + (isNaN(this.quarter_4Targets[i].value.replace(',', '.')) ? 0 : Number(this.quarter_4Targets[i].value.replace(',', '.')))).replace('.', ',');
        }
    }

    update_quarter_1_totals(event) {
        event.preventDefault();
        this.calculateQuarterTotal(this.quarter_1_totalTarget, this.quarter_1Targets);
    }

    update_quarter_2_totals(event) {
        event.preventDefault();
        this.calculateQuarterTotal(this.quarter_2_totalTarget, this.quarter_2Targets);
    }

    update_quarter_3_totals(event) {
        event.preventDefault();
        this.calculateQuarterTotal(this.quarter_3_totalTarget, this.quarter_3Targets);
    }

    update_quarter_4_totals(event) {
        event.preventDefault();
        this.calculateQuarterTotal(this.quarter_4_totalTarget, this.quarter_4Targets);
    }

    calculateQuarterTotal(quarter_total_target, quarter_targets) {
        quarter_total_target.textContent = String(quarter_targets.reduce((previous, current) => previous += (isNaN(current.value.replace(',', '.')) ? 0 : Number(current.value.replace(',', '.'))), 0)).replace('.', ',');
        this.year_totalTarget.textContent = String(Number(this.quarter_1_totalTarget.textContent.replace(',', '.')) + Number(this.quarter_2_totalTarget.textContent.replace(',', '.')) + Number(this.quarter_3_totalTarget.textContent.replace(',', '.')) + Number(this.quarter_4_totalTarget.textContent.replace(',', '.'))).replace('.', ',');
        for (const [i, year_prod_total] of this.year_prod_totalTargets.entries()) {
            year_prod_total.value = String((isNaN(this.quarter_1Targets[i].value.replace(',', '.')) ? 0 : Number(this.quarter_1Targets[i].value.replace(',', '.'))) + (isNaN(this.quarter_2Targets[i].value.replace(',', '.')) ? 0 : Number(this.quarter_2Targets[i].value.replace(',', '.'))) + (isNaN(this.quarter_3Targets[i].value.replace(',', '.')) ? 0 : Number(this.quarter_3Targets[i].value.replace(',', '.'))) + (isNaN(this.quarter_4Targets[i].value.replace(',', '.')) ? 0 : Number(this.quarter_4Targets[i].value.replace(',', '.')))).replace('.', ',');
        }
    }
}
