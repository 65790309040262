import GridController from "./grid_controller.js"

export default class extends GridController {
    initialize() {
        this.gridName = 'CompaniesGrid';
        this.gridConfig.columns = [
            {
                title: "ID",
                field: "id",
                type: "number",
                width: "100px",
                hidden: true,
            },
            {
                title: "Naam",
                field: "name",
                type: "string",
                width: "300px"
            },
            {
                title: "Straat",
                field: "street",
                type: "string",
                width: "300px",
                hidden: true,
            },
            {
                title: "Huisnummer",
                field: "number",
                type: "string",
                width: "100px",
                hidden: true,
            },
            {
                title: "Bus",
                field: "bus",
                type: "string",
                width: "100px",
                hidden: true,
            },
            {
                title: "Postcode",
                field: "postal_code",
                type: "string",
                width: "100px",
                hidden: true,
            },
            {
                title: "Stad",
                field: "city",
                type: "string",
                width: "200px",
                hidden: true,
            },
            {
                title: "Adres",
                field: "address",
                type: "string",
                width: "300px"
            },
            {
                title: "Aangemaakt op",
                field: "created_at",
                type: "date",
                width: "200px",
                format: "{0:yyyy/MM/dd HH:mm:ss}",
                hidden: true,
            },
            {
                title: "Laatst geupdate op",
                field: "updated_at",
                type: "date",
                width: "200px",
                format: "{0:yyyy/MM/dd HH:mm:ss}",
                hidden: true,
            },
        ];
        super.initialize();
    }
}
