import GridController from "./grid_controller.js"


export default class extends GridController {
    static values = {
        newButtonState: { type: Boolean, default: false },
        changedButtonState: { type: Boolean, default: false },
        newButtonVisible: { type: Boolean, default: false },
        changedButtonVisible: { type: Boolean, default: false },
        excelurl: String
    }

    initialize() {
        this.gridName = 'ProductionFacilitiesGrid';
        this.gridConfig.columns = [
            {
                title: "ID",
                field: "id",
                type: "number",
                width: "100px",
                hidden: true,
            },
            {
                title: "CERTIPRO-code",
                field: "certipro_code",
                type: "string",
                width: 140
            },
            {
                title: "Naam",
                field: "name",
                type: "string",
                width: "300px"
            },
            {
                title: "Straat",
                field: "street",
                type: "string",
                width: "300px",
                hidden: true,
            },
            {
                title: "Huisnummer",
                field: "number",
                type: "string",
                width: "100px",
                hidden: true,
            },
            {
                title: "Bus",
                field: "bus",
                type: "string",
                width: "100px",
                hidden: true,
            },
            {
                title: "Postcode",
                field: "postal_code",
                type: "string",
                width: "100px",
                hidden: true,
            },
            {
                title: "Stad",
                field: "city",
                type: "string",
                width: "200px",
                hidden: true,
            },
            {
                title: "Adres",
                field: "address",
                type: "string",
                width: "300px"
            },
            {
                title: "Mobiel",
                field: "mobile",
                type: "boolean",
                width: "100px",
                hidden: true,
            },
            {
                title: "Moet kwartaalcijfers indienen",
                field: "yearly_reports_required",
                type: "boolean",
                width: "100px",
            },
            {
                title: "Hoofdzetel",
                field: "company",
                type: "string",
                width: "300px"
            },
            {
                title: "Status",
                field: "status",
                type: "string",
                template: "<span class='badgeTemplate'>#=status#</span>",
                width: 120
            },
            {
                title: "Aangemaakt op",
                field: "created_at",
                type: "date",
                width: "200px",
                format: "{0:yyyy/MM/dd HH:mm:ss}",
                hidden: true,
            },
            {
                title: "Laatst geupdate op",
                field: "updated_at",
                type: "date",
                width: "200px",
                format: "{0:yyyy/MM/dd HH:mm:ss}",
                hidden: true,
            },
        ];

        this.gridConfig.toolbar = [];

        this.gridConfig.toolbar.push({ name: "Excel", text: this.localeValue == 'nl' ? "Exporteer naar Excel" : "exporter vers Excel", iconClass: "k-icon k-i-file-excel"});
        
        if (this.newButtonVisibleValue) {
            if (this.newButtonStateValue) {
                this.gridConfig.dataSource.transport.read.url = this.urlValue + '?filter=new';
                this.gridConfig.toolbar.push({
                    className: "k-state-active",
                    name: "New",
                    text: "Nieuw",
                });
            } else {
                this.gridConfig.toolbar.push({
                    name: "New",
                    text: "Nieuw"
                });
            }
        }
        if (this.changedButtonVisibleValue) {
            if (this.changedButtonStateValue) {
                this.gridConfig.dataSource.transport.read.url = this.urlValue + '?filter=changed';
                this.gridConfig.toolbar.push({
                    className: "k-state-active",
                    name: "Changed",
                    text: "Gewijzigd",
                });
            } else {
                this.gridConfig.toolbar.push({
                    name: "Changed",
                    text: "Gewijzigd"
                });
            }
        }
        super.initialize();

        $('.k-grid-Excel').on('click', function(event) {
            event.preventDefault();
            $.ajax({
                url: this.excelurlValue,
                xhrFields: {
                    responseType: 'blob'
                },
                type: 'GET',
                beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
                success: function(data, textStatus, jqXHR) {
                    const filenameHeader = jqXHR.getResponseHeader("content-disposition").split(';')[1];
                    const filename = filenameHeader.substring(filenameHeader.indexOf("=") + 1).replaceAll("\"", "");
                    const url = URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.append(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    alert('');
                },
            });
        }.bind(this));

        if (this.newButtonVisibleValue) {
            $(".k-grid-New").on('click', function(event) {
                event.preventDefault();

                if (!this.newButtonStateValue) {
                    this.url = this.urlValue + '?filter=new';
                    $(".k-grid-New").addClass('k-state-active');
                    $(".k-grid-Changed").removeClass('k-state-active');
                } else {
                    this.url = this.urlValue;
                    $(".k-grid-New").removeClass('k-state-active');
                }

                const dataSource = new kendo.data.DataSource({
                    transport: {
                        read: {
                            url: this.url,
                            cache: false,
                            dataType: "json",
                        }
                    },
                    pageSize: 15,
                    sort: { field: "created_at", dir: "desc" }
                });
                this.grid.setDataSource(dataSource);

                this.newButtonewButtonStateValuenState = !this.newButtonStateValue;
                this.changedButtonStateValue = false;
            }.bind(this));
        }
        if (this.changedButtonVisibleValue) {
            $(".k-grid-Changed").on('click', function(event) {
                event.preventDefault();

                if (!this.changedButtonStateValue) {
                    this.url = this.urlValue + '?filter=changed';
                    $(".k-grid-New").removeClass('k-state-active');
                    $(".k-grid-Changed").addClass('k-state-active');
                } else {
                    this.url = this.urlValue;
                    $(".k-grid-Changed").removeClass('k-state-active');
                }

                const dataSource = new kendo.data.DataSource({transport: {
                        read: {
                            url: this.url,
                            cache: false,
                            dataType: "json",
                        }
                    },
                    pageSize: 15,
                    sort: { field: "created_at", dir: "desc" }
                });
                this.grid.setDataSource(dataSource);

                this.newButtonStateValue = false;
                this.changedButtonStateValue = !this.changedButtonStateValue;
            }.bind(this));
        }
    }

    onDataBound(event) {
        const grid = this;
        grid.table.find("tr").each(function () {
            const dataItem = grid.dataItem(this);
            const themeColor = dataItem.status === 'Actief' ? 'success' : 'error';
            const text = dataItem.status;
            
            $(this).find(".badgeTemplate").kendoBadge({
                themeColor: themeColor,
                text: text,
            });

            kendo.bind($(this), dataItem);
        });
    }
}
