// Abstract base class for all Grid controllers
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        url: String,
        locale: { type: String, default: 'nl' },
        showAction: { type: Boolean, default: true },
        editAction: { type: Boolean, default: true },
        destroyAction: { type: Boolean, default: true }
    }

    handleGridEvent = (event) => {
        setTimeout(() => {
            this.saveGridState();
        });
    }

    actionButtonWidth = 50;
    actionColumnIndex;
    grid;
    gridConfig = {
        dataBound: this.onDataBound,
        dataSource: {
            transport: {
                read: {
                    url: this.urlValue,
                    cache: false,
                    dataType: "json",
                }
            },
        },
        columns: [],
        reorderable: true,
        sortable: {
            allowUnsort: false
        },
        toolbar: [{name: 'excel', text: this.localeValue == 'fr' ? "Exporter vers Excel" : "Exporteren naar Excel"}],
        excel: {
            fileName: "certipro-export.xlsx",
            filterable: true,
            allPages: true
        },
        pageable: false,
        resizable: true,
        filterable: {
            mode: "row",
            operators: {
                string: {
                    contains: this.localeValue == 'fr' ? "Contient" : "Bevat",
                    doesnotcontain: this.localeValue == 'fr' ? "Ne contient pas" : "Bevat niet",
                    eq: this.localeValue == 'fr' ? "Est égal à" : "Is gelijk aan",
                    neq: this.localeValue == 'fr' ? "N'est pas égal à": "Is niet gelijk aan",
                    startswith: this.localeValue == 'fr' ? "Commence avec": "Begint met",
                    doesnotstartswith: this.localeValue == 'fr' ? "Ne commence pas par": "Begint niet met",
                    endswith: this.localeValue == 'fr' ? "Se termine par": "Eindigt op",
                    doesnotendwith: this.localeValue == 'fr' ? "Ne se termine pas en": "Eindigt niet op",
                    isempty: this.localeValue == 'fr' ? "Est vide": "Is leeg",
                    isnotempty: this.localeValue == 'fr' ? "N'est pas vide": "Is niet leeg",
                },
                number: {
                    eq: this.localeValue == 'fr' ? "Est égal à" : "Is gelijk aan",
                    neq: this.localeValue == 'fr' ? "N'est pas égal à" : "Is niet gelijk aan",
                    gte: this.localeValue == 'fr' ? "Est supérieur ou égal à" : "Is groter of gelijk aan",
                    gt: this.localeValue == 'fr' ? "Est supérieur à" : "Is groter dan",
                    lte: this.localeValue == 'fr' ? "Est inférieur ou égal à" : "Is kleiner of gelijk aan",
                    lt: this.localeValue == 'fr' ? "Est inférieur à" : "Is kleiner dan",
                },
                date: {
                    eq: this.localeValue == 'fr' ? "Est égal à" : "Is gelijk aan",
                    neq: this.localeValue == 'fr' ? "N'est pas égal à" : "Is niet gelijk aan",
                    gte: this.localeValue == 'fr' ? "Est supérieur ou égal à" : "Is groter of gelijk aan",
                    gt: this.localeValue == 'fr' ? "Est supérieur à" : "Is groter dan",
                    lte: this.localeValue == 'fr' ? "Est inférieur ou égal à" : "Is kleiner of gelijk aan",
                    lt: this.localeValue == 'fr' ? "Est inférieur à" : "Is kleiner dan",
                },
                enums: {
                    eq: this.localeValue == 'fr' ? "Est égal à" : "Is gelijk aan",
                    neq: this.localeValue == 'fr' ? "N'est pas égal à" : "Is niet gelijk aan",
                },
            }
        },
        columnMenu: {
            filterable: false,
            sortable: false,
        },
        locale: this.localeValue
    };
    gridName

    initialize() {
        // Don't initialize the grid if the page is a Turbo cache preview,
        // see https://turbo.hotwired.dev/handbook/building#understanding-caching
        if (document.documentElement.hasAttribute("data-turbo-preview")) return;
        this.gridConfig = this.restoreGridState(this.gridConfig);
        this.setTemplates();
        this.addRowFilterFormatting();
        if (this.showActionValue) this.addShowAction();
        if (this.editActionValue) this.addEditAction();
        if (this.destroyActionValue) this.addDestroyAction();

        this.grid = $(this.element).kendoGrid(this.gridConfig).data('kendoGrid');

        $(this.element).kendoTooltip({
            filter: '.k-grid-show-button',
            content: function(e) {
                return this.localeValue == 'nl' ? 'bekijk' : 'montrer';
            }.bind(this)
        }).data("kendoTooltip");
        $(this.element).kendoTooltip({
            filter: '.k-grid-edit-button',
            content: function(e) {
                return this.localeValue == 'nl' ? 'wijzig' : 'modifier';
            }.bind(this)
        }).data("kendoTooltip");
        $(this.element).kendoTooltip({
            filter: '.k-grid-destroy-button',
            content: function(e) {
                return this.localeValue == 'nl' ? 'verwijder' : 'détruire';
            }.bind(this)
        }).data("kendoTooltip");
    }

    saveGridState() {
        const options = this.grid.getOptions();
        const strippedOptions = this.stripOptions(options);
        localStorage.setItem(this.gridName, JSON.stringify(strippedOptions));
    }

    stripOptions(options) {
        const dataSource = {
            fields: options.dataSource.fields.slice(0,-1),
            filter: options.dataSource.filter,
            group: options.dataSource.group,
            page: options.dataSource.page,
            pageSize: options.dataSource.pageSize,
            select: options.dataSource.select,
            sort: options.dataSource.sort
        };
        return {
            columns: options.columns.slice(0,-1),
            dataSource: dataSource
        };
    }

    restoreGridState(defaultOptions) {
        const options = JSON.parse(localStorage.getItem(this.gridName));
        if (options) {
            const {columns, dataSource} = options;
            const mergedDataSource = Object.assign({}, defaultOptions ? defaultOptions.dataSource : {}, dataSource);
            return Object.assign({}, defaultOptions ? defaultOptions : {}, {
                columns: columns,
                dataSource: mergedDataSource
            });
        }
        return defaultOptions;
    }

    setTemplates() {
        this.gridConfig.columns.forEach((column) => {
            if (typeof this[`${column.field}Template`] === 'function') {
                column.template = this[`${column.field}Template`];
            }
            if (typeof this[`${column.field}FilterTemplate`] === 'function') {
                column.filterable.cell.template = this[`${column.field}FilterTemplate`];
            }
            if (typeof this[`${column.field}FooterTemplate`] === 'function') {
                column.footerTemplate = this[`${column.field}FooterTemplate`];
            }
            if (typeof this[`${column.field}GroupFooterTemplate`] === 'function') {
                column.groupFooterTemplate = this[`${column.field}GroupFooterTemplate`];
            }
            if (typeof this[`${column.field}GroupHeaderTemplate`] === 'function') {
                column.groupHeaderTemplate = this[`${column.field}GroupHeaderTemplate`];
            }
            if (typeof this[`${column.field}HeaderTemplate`] === 'function') {
                column.headerTemplate = this[`${column.field}HeaderTemplate`];
            }
        });
    }

    onDataBound(event) {
    }

    showItem(event) {
        event.preventDefault();
        const rowData = this.dataItem($(event.target).closest("tr"));
        Turbo.visit(rowData.uri);
    }

    editItem(event) {
        event.preventDefault();
        const rowData = this.dataItem($(event.target).closest("tr"));
        Turbo.visit(rowData.uri + '/bewerken');
    }

    destroyItem(event) {
        event.preventDefault();
        let grid = this.element.data('kendoGrid');
        const rowData = this.dataItem($(event.target).closest("tr"));
        kendo.confirm(`Ben je zeker dat je dit item wil <strong>verwijderen</strong>?`).done(function () {
            $.ajax({
                url: rowData.uri + '.json',
                dataType: 'json',
                type: 'DELETE',
                beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
                success: function (data, textStatus, jqXHR) {
                    grid.removeRow(`tr[data-uid='${rowData.uid}']`);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    const response = JSON.parse(jqXHR.responseText);
                    alert(response.message);
                },
            });
        });
    }

    addActionsColumn() {
        if (typeof this.actionColumnIndex == 'number') return;
        this.gridConfig.columns.push({
            title: this.localeValue == 'fr' ? 'Actions' : "Acties",
            command: [],
            width: 0,
        });
        this.actionColumnIndex = this.gridConfig.columns.length - 1;
    }

    addShowAction() {
        this.addActionsColumn();
        this.gridConfig.columns[this.actionColumnIndex].width += this.actionButtonWidth;
        this.gridConfig.columns[this.actionColumnIndex].command.push({
            name: "show-button",
            text: " ",
            iconClass: "k-icon k-i-eye",
            click: this.showItem,
            visible: function (rowData) { return rowData.can_show },
        });
    }

    addEditAction() {
        this.addActionsColumn();
        this.gridConfig.columns[this.actionColumnIndex].width += this.actionButtonWidth;
        this.gridConfig.columns[this.actionColumnIndex].command.push({
            name: "edit-button",
            text: " ",
            iconClass: "k-icon k-i-pencil",
            click: this.editItem,
            visible: function (rowData) { return rowData.can_edit },
        });
    }

    addDestroyAction() {
        this.addActionsColumn();
        this.gridConfig.columns[this.actionColumnIndex].width += this.actionButtonWidth;
        this.gridConfig.columns[this.actionColumnIndex].command.push({
            name: "destroy-button",
            text: " ",
            iconClass: "k-icon k-i-trash",
            click: this.destroyItem,
            visible: function (rowData) { return rowData.can_destroy },
        });
    }

    addRowFilterFormatting() {
        for (let column of this.gridConfig.columns) {
            if (column.filterable === undefined) {
                switch (column.type) {
                    case "number":
                        // use cell.template->function(args) for row mode filtering i.s.o. ui->function(element)
                        column.filterable = {
                            cell: {
                                template: function (args) {
                                    args.element.kendoNumericTextBox({
                                        decimals: 0,
                                        format: "n0",
                                        min: 1,
                                        restrictDecimals: true,
                                    });
                                }
                            }
                        };
                        break;
                    case "date":
                        column.filterable = {
                            cell: {
                                template: function (args) {
                                    args.element.kendoDateTimePicker({
                                        format: "yyyy/MM/dd HH:mm:ss",
                                        timeFormat: "HH:mm"
                                    });
                                }
                            }
                        };
                        break;
                }
            }
        }
    }
}
